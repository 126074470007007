<template>
  <div>
<!-- lll{{ userData }}ss{{ baseUrl }} -->
    <!-- Media -->
    <b-media class="mb-2 d-flex align-items-center h-100">
      <template #aside>
      
        <b-avatar
          ref="previewEl"
          :src="baseUrl+userData.user.image"
          :text="avatarText(userData.user.name + userData.user.surname)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1 " style="text-transform: capitalize; font-weight: bold">
      {{ userData.user.name }}<br>
      {{userData.user.surname }}
      </h4>
      <!-- <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Fotoğraf Yükle</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Sil</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div> -->
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Bilgisayar Adı"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="userData.user.name"
              disabled
              placeholder="Test-12C"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Ad"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.user.name "
            />
          </b-form-group>
        </b-col>

        <!-- Field: last Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Soyad"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.user.surname "
            />
          </b-form-group>
        </b-col>
        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.user.email"
              type="email"
            />
          </b-form-group>
        </b-col>
  <!-- Field:tel  -->
        <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Telefon"
          label-for="phone"
        >
          <b-form-input
            id="company"
            v-model="userData.user.phoneNo"
            @input="validatePhoneNumber"
      pattern="[0-9]{11}"
      title="11 haneli bir numara giriniz"
      maxlength="11"

          />
        </b-form-group>
      </b-col>
  <!-- Field: Department -->
  <b-col
          cols="12"
          md="4"
          v-if="departmentList.length>0"
         
        >
          <b-form-group
            label="Departman"
            label-for="user-role"
          >
            <v-select
            v-if="!isLoading"
              v-model="department"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="departmentList"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Status -->
        <b-col
          cols="12"
          md="4"
          v-if="userRole.generalRole === 'Admin'"
        >
          <b-form-group
            label="Departman Rolu"
            label-for="user-status"
           
          >
         <!-- {{ userData.departmentRole }} -->
            <v-select
              v-model="userData.departmentRole"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="departmentRoles"
              :reduce="val => val.key"
              item-value="key"
              item-text="label"
              :clearable="false"
              
              input-id="user-status"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
          v-if="userRole.generalRole === 'Admin'"
        >
          <b-form-group
            label="Kullanıcı Rolu"
            label-for="user-role"
            
          >
         
            <v-select
              v-model="userData.user.generalRole"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.key"
              :clearable="false"
              item-value="id"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>
  

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Şifre"
            label-for="company"
          >
            <b-form-input
              id="company"
              v-model="password"
            />
          </b-form-group>
        </b-col>

       

      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->
    <!-- <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card> -->

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
    
      @click="userUpdate"
    >
      Güncelle
    </b-button>
    <router-link :to="{ name: 'user-settings'}"> <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      İptal
    </b-button></router-link>
   
  </div>
</template>

<script>
import {getUserData} from '@/auth/utils'
  import { Api } from '@/helpers/Api/apiConfig'
  import { BASE_URL } from '@/helpers/Url/BaseUrl'
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useUsersList from '../users-edit/useUsersList'

export default {
  components: {
    BASE_URL,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  
  props: {
    userData: {
      type: Object,
      required: true,
    },
   },
  watch: {
    userData: {
      handler(val) {
        this.userData = val
      }
    }
  },
  data() {
    return {
      userRole: getUserData(),
      //userData:null,
      userDepartmentId:null,
      baseUrl:BASE_URL,
      departmentRoles:[
     
      { label: 'Departman Yöneticisi', value: true ,key:'DepartmentManager',id:1},
      { label: 'Departman Çalışanı', value: false ,key:'DepartmentWorker',id:2},
    ]
    
    }
  },
  setup(props) {
  
    const { resolveUserRoleVariant } = useUsersList()

    const roleOptions = [
      { label: 'Admin', value: true, key:'Admin',id:1},
      { label: 'Kullanıcı', value: false ,key:'User',id:2},
      // { label: 'Editor', value: 'editor' },
      // { label: 'Maintainer', value: 'maintainer' },
      // { label: 'Subscriber', value: 'subscriber' },
    ]
    const departmentList = [
      // { label: 'Admin', value: true },
      // { label: 'Kullanıcı', value: false },
      // { label: 'Editor', value: 'editor' },
      // { label: 'Maintainer', value: 'maintainer' },
      // { label: 'Subscriber', value: 'subscriber' },
    ]
   

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

const department = ref('sd');
const isLoading = ref(false);
const password = ref('');

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.image = base64
    })

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
     // departmentRoles,
      permissionsData,
      departmentList,
      department,
      isLoading,
      password,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  mounted() {
   this.getUserDepartment()

    this.getDepartmentsDetail();
  },
  methods: {
    validatePhoneNumber() {
      // Telefon numarasının uzunluğunu kontrol et
      if (this.userData.phone.length > 11) {
        // Eğer 11 haneliden fazla ise, son girişi iptal et
        this.userData.phone = this.userData.phone.slice(0, 11);
      }
    },
    async getDepartmentsDetail() {
      this.isLoading = true;
      await Api("GET", "Department/GetAllActiveDepartments").then((res) => {
        this.departments = res.data.data.activeDepartments;
        this.departmentList = this.departments.map((item) => {
          return {
            label: item.name,
            value: item.departmentId,
            //isActive:false
          };
        });
        // this.$emit('departmentList',this.departmentList)
        console.log( 'userreditdepartments',this.departments);
        this.getUserDepartment();
        this.isLoading = false;
      });
    },
    async getUserDepartment() {
      this.isLoading = true;
      let userId = this.$route.params.id;
      
      await Api("GET", "DepartmentUser?userId="+userId ).then((res) => {
        let departmentId = res.data.data.items[0].department.departmentId;
        this.department = this.departmentList.find((item) => {
          return item.value === departmentId;
        }).value
        // this.$emit('departmentList',this.departmentList)
        console.log( 'userreditdepartments',this.departments,'user',res.data.data.items[0].user);
        this.userDepartmentId = res.data.data.items[0].department.departmentId
        this.userData == null?this.userData = res.data.data.items[0].user:null
        this.userData = {...this.userData,isManager:res.data.data.items[0].departmentRole }
      console.log('userdata',this.userData);
        this.isLoading = false;
      });
    },
    async UpdateDepartmentUser() {
      //this.isLoading = true;
      let userId = this.$route.params.id;
      const role=this.departmentRoles.find(e=>e.key===this.userData.departmentRole)
      await Api("PUT", "DepartmentUser/"+this.userData.departmentUserId,{
        
        
        departmentId:this.department,
        userId:userId,
        departmentRole:role.id
      
      
      }).then((res) => {
       alert('Kullanıcı güncellendi.')
        console.log( 'DepartmentUser update',res);
        
        
       
      });
    },
   async userUpdate(){
    const role=this.roleOptions.find(e=>e.key===this.userData.user.generalRole)
      let userId = this.$route.params.id;

      
      await Api("PUT", "User/"+userId,{
        name:this.userData.user.name,
        surname:this.userData.user.surname,
        email:this.userData.user.email,
        phoneNo:this.userData.user.phoneNo,
       password:this.password ? this.password : '',
        timeLoop:this.userData.user.timeLoop,
        generalRole:role.id,
        //isDelete:userData.isDelete,
        //computerName:userData.computerName
      
      
      
      }).then((res) => {
       
       console.log( 'user update',res);
        this.UpdateDepartmentUser()
   
     });

    
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
