<template>
  
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- ss{{ userData }} -->
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>
    <!--  -->
    <b-tabs
      v-if="userData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Hesap Bilgileri</span>
        </template>
       
        <user-edit-tab-account
       v-if="!isLoading"
          :userData="userData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <!-- <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <user-edit-tab-information class="mt-2 pt-75" />
      </b-tab> -->

      <!-- Tab: Social -->
      <!-- <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Social</span>
        </template>
        <user-edit-tab-social class="mt-2 pt-75" />
      </b-tab>-->
    </b-tabs> 
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
//import { ref, onUnmounted } from '@vue/composition-api'
// import router from '@/router'
// import store from '@/store'
// import userStoreModule from '../userStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'
//import UserEditTabInformation from './UserEditTabInformation.vue'
//import UserEditTabSocial from './UserEditTabSocial.vue'
// Import the event bus
import { Api } from '@/helpers/Api/apiConfig'
import {getUserData} from '@/auth/utils'
import { mapGetters } from "vuex";
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
   // UserEditTabInformation,
   // UserEditTabSocial,
  },
  data() {
    return {
      userRole: getUserData(),
      isLoading:true,
      // userData:this.userDetail,
      userData: null,
    //   userData: {
      


    //     id: 90,
    // name: "Aslı",
    // lastname: "Çelik",
    // email: "heocademy@gmail.com",
    // phone: "05064564545",
    // image: "/Images/8d965f4c-6c3b-4888-ac02-69837f9ff68c.jpg",
    // timeLoop: 10,
    // isAdmin: false,
    // isDelete: false,
    // computerName:'Eralp-5'
     
    //   },
    }
  
  },
  
  // computed: {
  //   ...mapGetters({ userData: "settingsUser/userData"}),

 
  // },
watch: {
  userData(val) {
      this.userData = val;
    }
},
  mounted() {
    this.getUserDepartment()
  },
  methods: {
    async getUserDepartment() {
      this.isLoading = true;
      let userId = this.$route.params.id;
      // alert(userId)
      await Api("GET", "DepartmentUser?userId="+userId ).then((res) => {
        // let userDepartmentId = res.data.data[0].departmentId;
        // this.department = this.departmentList.find((item) => {
        //   return item.value == userDepartmentId;
        // });
        // this.$emit('departmentList',this.departmentList)
        console.log( 'user',res.data.data.items[0]);
        !this.userData ?this.userData = res.data.data.items[0]:null
        this.isLoading = false;
      });
    },
  }
}
</script>

<style>

</style>
